import styled from "styled-components";

export const Layout = styled.div`
  padding: 30px;
`;

export const CalendarBox = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px 0;
`;

export const DateBox = styled.div``;
export const Box = styled.div`
  padding: 30px;
`;
