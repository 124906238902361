import styled from "styled-components";

export const FeedUl = styled.ul`
  width: 100%;
  height: 100%;
`;
export const Observer = styled.div`
  width: 100%;
  height: 20px;
`;
