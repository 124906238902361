const colors = {
  green: {
    _50: "#e6f0eb",
    _100: "#b1d0c0",
    _200: "#8bb9a2",
    _300: "#569878",
    _400: "#35855d",
    _500: "#036635",
    _600: "#035d30",
    _700: "#024826",
    _800: "#02381d",
    _900: "#012b16",
  },
  white: {
    _50: "#ffffff",
    _100: "#f7f7f7",
    _200: "#f4f4f4",
    _300: "#eeeeee",
    _400: "#ebebeb",
    _500: "#e6e6e6",
    _600: "#d1d1d1",
    _700: "#a3a3a3",
    _800: "#7f7f7f",
    _900: "#616161",
  },
  red: {
    _50: "#fee6e6",
    _100: "#fab0b0",
    _200: "#f88a8a",
    _300: "#f55454",
    _400: "#f33333",
    _500: "#f00000",
    _600: "#da0000",
    _700: "#aa0000",
    _800: "#840000",
    _900: "#650000",
  },
  yellow: {
    _50: "#fffbe6",
    _100: "#fff2b0",
    _200: "#ffeb8a",
    _300: "#ffe254",
    _400: "#ffdd33",
    _500: "#ffd400",
    _600: "#e8c100",
    _700: "#b59700",
    _800: "#8c7500",
    _900: "#6b5900",
  },
  orange: {
    _50: "#fff3e7",
    _100: "#ffd9b5",
    _200: "#ffc691",
    _300: "#ffac5e",
    _400: "#ff9c3f",
    _500: "#ff830f",
    _600: "#e8770e",
    _700: "#b55d0b",
    _800: "#8c4808",
    _900: "#6b3706",
  },
  black: {
    _50: "#ebebeb",
    _100: "#c1c1c1",
    _200: "#a3a3a3",
    _300: "#7a7a7a",
    _400: "#606060",
    _500: "#383838",
    _600: "#333333",
    _700: "#282828",
    _800: "#1f1f1f",
    _900: "#181818",
  },
  beige: {
    _50: "#faf7f4",
    _100: "#f0e7de",
    _200: "#e9dbce",
    _300: "#dfcab8",
    _400: "#d9c0aa",
    _500: "#cfb095",
    _600: "#bca088",
    _700: "#937d6a",
    _800: "#726152",
    _900: "#574a3f",
  },
  blue: {
    _50: "#eaf3ff",
    _100: "#bdd9ff",
    _200: "#9dc7ff",
    _300: "#70aefe",
    _400: "#559efe",
    _500: "#2a86fe",
    _600: "#267ae7",
    _700: "#1e5fb4",
    _800: "#174a8c",
    _900: "#12386b",
  },
  purple: {
    _50: "#f8eaff",
    _100: "#e8bdff",
    _200: "#dd9dff",
    _300: "#cd70fe",
    _400: "#c355fe",
    _500: "#b42afe",
    _600: "#a426e7",
    _700: "#801eb4",
    _800: "#63178c",
    _900: "#4c126b",
  },
};
export default colors;
