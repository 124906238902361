import styled from "styled-components";
import colors from "../Common/colors";

export const Layout = styled.div`
  width: 100%;
  background-color: white;
  margin-bottom: 10px;
`;

export const Bottom = styled.div``;
